.retailer-wrapper .declaration {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%;
  max-width: 800px;
  margin: auto;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.retailer-wrapper .signature {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.retailer-wrapper .sigCanvas {
  border: 1px solid #000;
  margin-top: 10px;
}

.retailer-wrapper .innerList {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.retailer-wrapper li {
  font-size: 13px;
  list-style-type: disc;
  margin-left: 20px;
}

.retailer-wrapper li::marker {
  font-size: 16px;
}

.retailer-wrapper button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 12px;
}

.retailer-wrapper h1 {
  margin-bottom: 20px;
  font-size: 20px;
  text-align: center;
}

.retailer-wrapper p {
  font-size: 13px;
  line-height: 1.6;
}
