/* Burger menu styles */
.burger-menu {
    position: fixed !important;
    top: 18px;
    left: 20px;
    z-index: 1000;
    cursor: pointer;
    transition: bottom 0.3s ease;
  }
  
  .burger-icon {
    width: 30px;
    height: 10px;
    position: relative;
  }
  
  .burger-icon span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background:rgb(21 67 176);
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }
  
  .burger-icon span:nth-child(1) {
    top: 2px;
  }
  
  .burger-icon span:nth-child(2) {
    top: 8px;
  }
  
  .burger-icon span:nth-child(3) {
    top: 14px;
  }
  
  .burger-icon.open span:nth-child(1) {
    top: 9px;
    transform: rotate(135deg);
    right: 0;
  }
  
  .burger-icon.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
  }
  
  .burger-icon.open span:nth-child(3) {
    top: 9px;
    transform: rotate(-135deg);
  }
  
  /* Mobile sidebar styles */
  .left-panel.mobile {
    position: fixed;
    left: -250px;
    top: 0;
    height: 90vh;
    z-index: 999;
    transition: left 0.3s ease-in-out;
    background: linear-gradient(180deg, #e1eaff 100%, rgba(255, 255, 255, 0) 100%) !important;

  }
  
  .left-panel.mobile.open {
    left: 0;
    width: 50%;
    height: 100vh;
  }

  .burger-icon.open{
    right: -142px;
    top:10px
  }
  .burger-menu.hidden {
    bottom: -150px;
}