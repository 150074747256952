.TabsDiv{
  display: flex;
  justify-content: right;
  align-items: center;
  width:50%;
  height: 90%;
}

.TabsDiv button {
  border: 1px solid #ccc;
  padding: 8px 16px;
  cursor: pointer;
  display:flex;
  gap:8px;
  align-items: center;
  width: 46%;
  /* height: 100%; */

}

.XMLButton{
  justify-content: space-around !important;
  /* line-height: 27px; */
  font-size:12px !important;

 
}


.MuiBadge-anchorOriginBottomRight
{
 
  bottom: 0px;
  right: 0px; 
}

.tabsButtonDIv{
  display: flex;
  height: 100%;
}
.DashboardCardFirstSectionRow{
  display: flex;
  flex-direction: row;
}
.DashboardFirstSection{
  display: flex;
  flex-direction: column;
  width:85%;
}


.buttonForReports{
  position: relative;
  color:rgb(21,67,176);
  background: #fff;
  border: 1px solid rgb(21,67,176);
  border-radius: 50px;
  height: 34px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover{
    background: rgb(21 67 176);
    color: rgba(255, 255, 255, 1);
  }
}

.all-person{
  display: flex;
  flex-direction: column;
  /* gap:px */
}

.location-icon{
  width:18px !important
}
.panel-details{
  min-width: 50%;
  text-align: start;
  display:flex;
  flex-direction: column;
  gap:10px
}
@media (min-width: 1701px) {
  .location-icon{
    width:19px !important
  }
}
@media (min-width: 800px) and (max-width: 1668px) {
  .jobCardRightSection{
    width: 28% !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .all-person{
    flex-wrap: wrap !important;
  }
}