.download-stc-form .mainContainer {
  width: 80%;
  margin: auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  font-size: 12pt;
}

.download-stc-form .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.download-stc-form .ref {
  margin-top: 10px;
}

.download-stc-form .section {
  margin-top: 20px;
  border-top: 2px solid black;
  padding-top: 10px;
}

.download-stc-form .owner-details,
.download-stc-form .system-details,
.download-stc-form .panel-details,
.download-stc-form .inverter-details,
.download-stc-form .battery-details,
.download-stc-form .installer-details {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.download-stc-form h3 {
  grid-column: 1 / -1;
  background-color: black;
  color: white;
  padding: 5px;
}

.download-stc-form button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: blue;
  color: white;
  border: none;
  cursor: pointer;
}

/* NEW STC FORM CSS */
.STCFormContainer{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}

.STCFormHeaderContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  /* margin: 1% 2%; */
}

.InnerContainerSTC{
  padding:5% 8%;
  /* width:80vw ; */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

}

@page {
  size: A4;
  margin: 2%;
}
 .sectionDivider{
  border-top: 1px solid blue;
  margin-top:1% ;
  
}
.DetailSection{
  /* padding:3% 2% 1%; */
  margin: 1% 0 3% ;
  height:fit-content;
}
.Details-text{
  color: #505251;
  font-size: 1.2rem;
  text-decoration: underline;
  font-weight: 550;
  /* line-height: 2; */
  /* padding: 1% 0; */

  
}
.dynamicDatatext {
  font-size: 0.87rem;
  padding-left: 1%;
  text-decoration: none !important;
  font-weight: 500;
}
.DetailsSmallText{
  font-size: 0.8rem;
  padding: 5px 0;
}
 .DetailRowSection{
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
}

table {
  border-collapse: collapse;
  width: 100%;
  text-align: left;
}
.DetailSimpleText{
  font-weight: 300;
  font-size: 0.82rem;
  text-decoration: none !important;
  padding-left: 0;
  color: #000;
}
.WrittenStatement{
  padding-top: 1%;
}
.DetailsSectionTitle{
  /* margin: 2% 0; */
  /* align-items: center; */
}


/* MAterial UI  */

.inputBoxConatiner{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
   margin-top: 3%;
   text-align: left;
}

.PageBreakBeforeSection{
  page-break-before: always;
  margin-top: 6%;

}
