.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Arial, sans-serif;
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 2px 2px 10px #eee;
}

.customer-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.list {
  list-style-type:disc;
  padding-left: 20px;
}

.list li {
  margin-top: 10px;
  list-style-type: disc;
}

.list p {
  font-size: small;
  margin: 5px 0 0 5px;
}
