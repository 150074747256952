.TitleSection{
   padding:2% 1% 0;
}

.textFont{
  /* padding: 10px 10px; */
  display: inline-block;
  color: rgba(44, 58, 100, 1);
  font-size: 16px;
  font-weight: 700;
  line-height: 15px;
}
.SectionType{
  margin: 2% 1%;
}
.FormDetailsHotWaterJOb{
  width: 98%;
  margin-left: 1%;
}
.innerSectionDetails{
  display: flex;
  align-items: center;
  gap:25px;
  /* padding-top: 25px; */
  padding-left: 10px;
}

.innerSectionDetails .search-panel{
  /* margin-left: 10px; */
}
.ColumnSection{
  display: flex;
  flex-direction: column;
}
 .innerSectionDetails p{
  margin-left: 10px;
 }

.SectionWithClipBoard input{
   margin-left: 10px;
}
.subSectionInstallationteamHW{
  padding: 10px 30px;
  display: flex;
  align-items: center;
  gap:30px
}