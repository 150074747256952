.boxStyle{
    width: 24vw;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin: 10px;
    position: relative;
    box-shadow: 5px 10px 10px rgba(0, 10, 120, 0.5);
    gap:30px;
    background-color: #e1eaff;
    cursor: pointer;

}
.homeContainerMain{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.homeDataSections{
display: flex;
width: 100%;
justify-content: center;
flex-direction: row;
}

.PieChartDiv{
    text-align: center;
}
.graphArea{
    height: 650px;
    width: 600px;
    margin-top: 90px ;
}

.dataTooltip{
    font-weight: 600;
    color:#1543b0
}
@media (min-width:1206) {
    .graphArea{
        width: 600px !important;
        height: 300px !important;
    }
}

@media (max-width:670px) {
    .homeDataSections{
       flex-direction: column;
       
        }
    .boxStyle{
        width:93%;
    }
    .GraphDiv{
        width: 90%;
    }
    .graphArea{
        height: 150px !important;
        width: 370px !important;
    
    }
}   