@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;500&family=Overpass:ital@0;1&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  font-family: Poppins;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
:root {
  /* fonts */
  /* --font-poppins: Poppins; */
  --font-work-sans: "Work Sans";

  /* font sizes */
  --font-size-4: 0.25rem;
  --font-size-6:0.375rem;
  --font-size-8: 0.5rem;
  --font-size-10: 0.625rem;
  --font-size-12: 0.75rem;
  --font-size-14: 0.875rem;
  --font-size-16: 1rem;
  --font-size-18: 1.125rem;
  --font-size-20: 1.25rem;
  --font-size-22: 1.375rem;
  --font-size-24: 1.5rem;
  --font-size-26: 1.625rem;
  --font-size-28: 1.75rem;
  --font-size-30: 1.875rem;
  --font-size-32: 2rem;
  --font-size-34: 2.125rem;
  --font-size-36: 2.25rem;
  --font-size-38: 2.375rem;
  --font-size-40: 2.5rem;

  /*  font weight  */
  --font-weight-1: 100;
  --font-weight-2: 200;
  --font-weight-3: 300;
  --font-weight-4: 400;
  --font-weight-5: 500;
  --font-weight-6: 600;
  --font-weight-7: 700;
  --font-weight-8: 800;

  /* Colors */
  --color-dimgray: #525252;
  --color-black: #000;
  --color-aliceblue: #eff6ff;
  --color-lightblue: #5fa4fb;
  --color-lightgrey: #f0f4f4;
  --color-normalgrey: #888888;
  --color-lightGrey: #f6f6f6;
  --color-dimgrey: #d3d3d3;
  --color-normalwhite: #ffffff;
  --color-normalblack: #000000;
  --color-darkblack: #2d2d2d;

  /* Gaps */
  ---btn-regu-gap: 6px 14px 6px 14px;
  /* --gap-8xs: 5px; */
  /* --gap-3xs: 10px; */

  /* Border radiuses */
  --btn-radius: 30px;
}
select option {
  background-color: transparent;
}
/* Media Q */

/* Media Q */
.hamburger{
  
  width: 20px;
  height: 20px;
  display: none;
  border: none;
  padding: 0px;
  margin: 20px;
  background: linear-gradient(
    to bottom, 
    var(--color-lightblue), var(--color-lightblue) 20%, 
    white 20%, white 40%, 
    var(--color-lightblue) 40%, var(--color-lightblue) 60%, 
    white 60%, white 80%, 
    var(--color-lightblue)80%, var(--color-lightblue) 100%
  );
}
@media screen and (max-width: 1150px) {
  .hamburger{
    display: block;
    cursor: pointer;
  }
}


#hamburger{
  display: block;
  position: fixed;
  right: 10px;
  top: 70px;
  background-color:var(--color-lightgrey);
  padding: 20px;
  border-radius: 30px;
  box-shadow: 0px 4px 20px 9px #00000017;
  overflow-y: scroll;
  /* z-index: 2; */
  max-height: 600px; 
  /* border: 1px solid; */
}
/**  ParentComponents css Start from here  **/

.parent-container {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  border-radius: 40px;
  padding: 30px;
  /* height: 100%; */

  background-color: var(--color-normalwhite);
  box-shadow: 0px 4px 44px 0px #0000001a;
}
/* @media screen and (max-width: 1150px) {
  .parent-container {
 padding: 30px 60px;
  }
} */

.test {
  background-image: url("/public/Assets/images/dashboardNavBarIcon/installer.svg");
  width: 30px;
  height: 30px;
  margin-right: 10px;
  background-size: cover;
  background-repeat: no-repeat;
}

.parent_section-one {
  /* width: 950px; */
  flex-grow: 1;
  /* padding: 30px; */
  width: 1030px;
}


.horizontal-line {
  margin-top: 63px;
  margin-right: 15px;
  height: 912px;
  /* margin-right: 15px; */
  border-radius: 50px;
}
@media screen and (max-width: 1150px) {
  .horizontal-line {
  display: none;
  }
}
.parent_section-two {
  /* flex-grow: 1; */
  /* padding-top: 30px; */
  margin-left: 20px;
  display: flex;

  /* border: 1px solid; */
}

.parent-sectionTwo-child {
  /* padding: 0px 10px 0 15px; */
}
 @media screen and (max-width: 1150px) {

  .parent-sectionTwo-child{
    display: none;
  }
} 
/**  ParentComponents css end here  **/

/**  TopHeader css start from here  **/

.TopHeader-container {
  display: flex;
  /* justify-content: space-between; */
  gap: 50px;
}

.section-one-ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}
.section-one-ul li a {
  text-decoration: none;
  font-weight: var(--font-weight-4);
  font-size: var(--font-size-20);
  color: var(--color-lightblue);
  font-family: Poppins;
  line-height: 36px;
  /* width: 100px;
  height: 100px;
  transition: transform 0.3s ease; */
}

.section-one-ul li a:hover {
  position: relative;
  text-decoration: none;
  font-weight: var(--font-weight-6);
  font-family: Poppins;
  line-height: 36px;
  transition: font-weight 0.5s;
}
.section-one-ul li a:hover::after {
  content: "";
  position: absolute;
  font-weight: var(--font-weight-6);
  font-family: Poppins;
  line-height: 36px;
  left: 20px;
  bottom: -3px;
  width: 50px;
  border-bottom: 3px solid var(--color-lightblue);
}

.section-one-ul li a {
  width: 100px;

  display: inline-block;
}

.section-two {
  /** margin-left: 165px; **/
  position: relative;
  display: flex;

  justify-content: center;
  align-items: center;
}

.section-two a {
  top: 20px;
  right: 20px;
  position: absolute;
  cursor: pointer;
}
.section-two input {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 365.35px;
  height: 50px;
  border-radius: 30px;
  padding: 16px;
  font-size: var(--font-size-16);
  border: 1px solid var(--color-dimgrey);
  font-weight: var(--font-weight-4);
  font-family: Poppins;
}
.section-two input::placeholder {
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-4);

  font-family: Poppins;
  color: #696969;
}
.nav-link.active {
  position: relative;
  text-decoration: none;
  font-weight: var(--font-weight-6);
  font-family: Poppins;
  line-height: 36px;
  display: inline-block;
  width: 100px;
}
.nav-link.active::after {
  content: "";
  position: absolute;
  font-weight: var(--font-weight-6);
  font-family: Poppins;
  line-height: 36px;
  left: 20px;
  bottom: -3px;
  width: 50px;
  border-bottom: 3px solid var(--color-lightblue);
}

/**  TopHeader css end here  **/

/** Dashboard menu start from here **/

.dashboard-icon.active {
  font-family: Poppins;
  font-weight: var(--font-weight-7);
  font-size: var(--font-size-20);
  line-height: 33px;
  text-decoration: none;
  text-decoration: 3px underline;
  text-decoration-color: #ffb56d;
  color: var(--color-lightblue);

  text-underline-position: under;
}

.dashboardmenu-container {
  display: flex;
  /* margin: 40px 0 30px 0; */
  margin: 25px 0px 15px 0px;
  display: flex;
  align-items: center;
  gap: 40px;
}
.dashboardmenu-container a {
  font-family: Poppins;
  font-weight: var(--font-weight-4);
  font-size: var(--font-size-20);
  line-height: 33px;
  text-decoration: none;
  color: var(--color-normalblack);
}

.dashboardmenu-container a:hover {
  font-family: Poppins;
  font-weight: var(--font-weight-7);
  font-size: var(--font-size-20);
  line-height: 33px;
  text-decoration: none;
  color: var(--color-normalblack);
  transition: font-weight 0.3s;
}
#dashboardIcon_margRgiht {
  margin-right: 15px;
}
.dashboard_Icon {
  background-image: url("/public/Assets/images/dashboardNavBarIcon/dashboard.svg");
  width: 30px;
  height: 30px;
  margin-right: 15px;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
}

.dashboard-li-container:hover .dashboard_Icon {
  background-image: url("/public/Assets/images/dashboardNavBarIcon/dashboardHov.svg");
  width: 30px;
  height: 30px;
  margin-right: 15px;
  background-size: cover;
  background-repeat: no-repeat;
}
.jobList_Icon {
  background-image: url("/public/Assets/images/dashboardNavBarIcon/job.svg");
  width: 30px;
  height: 30px;
  margin-right: 15px;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
}

.dashboard-li-container:hover .jobList_Icon {
  background-image: url("/public/Assets/images/dashboardNavBarIcon/joblistHov.svg");
  width: 30px;
  height: 30px;
  margin-right: 15px;
  background-size: cover;
  background-repeat: no-repeat;
}
.installer_Icon {
  background-image: url("/public/Assets/images/dashboardNavBarIcon/installer.svg");
  width: 30px;
  height: 30px;
  margin-right: 15px;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
}
.dashboard-li-container:hover .installer_Icon {
  background-image: url("/public/Assets/images/dashboardNavBarIcon/InstallerHov.svg");
  width: 30px;
  height: 30px;
  margin-right: 15px;
  background-size: cover;
  background-repeat: no-repeat;
}

.profile_Icon {
  cursor: pointer;
  background-image: url("/public/Assets/images/dashboardNavBarIcon/profile.svg");
  width: 30px;
  height: 30px;
  margin-right: 15px;
  background-size: cover;
  background-repeat: no-repeat;
}
.dashboard-li-container:hover .profile_Icon {
  background-image: url("/public/Assets/images/dashboardNavBarIcon/profileHov.svg");
  width: 30px;
  height: 30px;
  margin-right: 15px;
  background-size: cover;
  background-repeat: no-repeat;
}
.dashboard-li-container:hover .dashboard-icon {
  text-decoration: 3px underline;
  text-decoration-color: #ffb56d;
  color: var(--color-lightblue);

  text-underline-position: under;
}

.dashboard-img {
  margin-right: 18px;
  /* margin-left: 69px;  */
}

.dashboard:active {
  text-decoration: 3px underline;
  text-decoration-color: #ffb56d;
  color: var(--color-lightblue);
  font-family: Poppins;
  font-weight: var(--font-weight-7);
  font-size: var(--font-size-20);
  line-height: 33px;
}

.dashboard-li-container {
  /* padding-left: 30px;  */

  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.dashboard-icon {
  padding-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard-icon p {
  width: 90px;
  height: 40px;
}

/** Dashboard menu end here **/

/** Status start from here **/
.status-box {
  display: flex;
  justify-content: space-between;
}

.container-status-blueBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
}

.status-box-one {
  height: 100%;
  width: 100%;
  cursor: pointer;

  border-radius: 80px;
  background-color: var(--color-lightblue);
  display: flex;
  justify-content: center;
  align-items: center;
}
.status-box-one p {
  font-size: 60px;
  color: white;
}

.status-box-two {
  height: 140px;
  /* width: 820px; */
  box-shadow: 0px 4px 14px 0px var(--color-normalblack) 26;

  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  border-radius: 24px;
  background-color: var(--color-lightblue);
}

/** inside box  **/
.statusBox-heading {
  font-size: var(--font-size-10);
  font-weight: var(--font-weight-3);
  line-height: 10px;
  font-family: Poppins;
  color: var(--color-normalwhite);
  margin-top: 22px;
  text-align: start;
}

.statusBox-data {
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-3);
  line-height: 44px;
  font-family: Poppins;
  color: var(--color-normalwhite);
  margin-top: 2px;
  text-align: start;
}
.Status-container{
  flex: 1;
}




@media screen and (max-width: 1220px) {

  .container-status-blueBox {
    width: 140px;
    height: 140px;
  }
}




.statusBox-pracentage-box {
  width: 70px;
  height: 30px;
  background-color: #d7eea1;
  border: 1px;
  text-align: center;
  margin-top: 6px;
  border-radius: 1000px;
}

.statusBox-pracentage {
  font-family: Poppins;
  font-weight: var(--font-weight-5);
  font-size: var(--font-size-10);

  line-height: 27px;
 
}

.manageBtn-status {
  text-align: center;
  /** margin-left: 400px; **/

  text-align: end;
}

.manageBtn-status a {
  font-family: Poppins;
  font-weight: var(--font-weight-5);
  font-size: var(--font-size-14);
  line-height: 27px;
  text-decoration: none;
  color: #020202;
}

.vartical-line-status {
  border-left: 1px solid #dedede;
  height: 67px;
  margin-top: 48px;
}

/** status.jsx end here **/

/** popup start from here  **/

.pop-container {
  /** width: 583px;
  height: 589px; **/
  display: flex;
  flex-direction: column;
  width: 560px;
  height: 550px;
  background: var(--color-lightgrey);
  border-radius: 40px;
  box-shadow: 0px 0px 40px 0px #00000026;

  /* position: fixed; */
  /* left: 450px;
  top: 65px; */

  gap: 30px;
  padding: 30px 20px;
}

.pop-heading {
  /* text-align: center; */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

}
.pop-heading span {
  font-size: 42px;
  line-height: 63px;
  font-weight: var(--font-weight-5);
  font-family: Poppins;

}

.PopupClose_byImg{
  position: absolute;
  right: 0;
  cursor: pointer;
}

.pop-create-job {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  text-align: center;
}

.pop-spv p {
  font-size: var(--font-size-20);
  font-weight: var(--font-weight-4);
  line-height: 33px;
  font-family: Poppins;
}
.pop-spv a {
  width: 250px;
  height: 250px;
  background-color: var(--color-normalwhite);
  border-radius: 30px;

  cursor: pointer;
  display: inline-block;
  border: none;
}

.pop-swh p {
  font-size: var(--font-size-16);
  line-height: 24px;
  font-weight: var(--font-weight-5);
  font-family: Poppins;
  color: #a4a4a4;
}

.pop-swh div {
  background-color: var(--color-normalwhite);
  border-radius: 30px;
  width: 250px;
  height: 250px;

  border: 1px solid;
  cursor: pointer;
  display: inline-block;
}

.pop-create-job div {
}
.PopupDashboard-conatiner-main {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}
.pop-swh a {
  background-color: var(--color-normalwhite);
  border-radius: 30px;
  width: 250px;
  height: 250px;

  cursor: pointer;
  display: inline-block;
}

.createjobPopup-step2_main-container {
  position: static;
  top: 0;
  right: 0;
}
/** popup end here **/

/** JobStatus.jsx start from here  **/

#actived.active {
  text-decoration: none;
  color: var(--color-lightblue);
  font-family: Poppins;
  font-weight: var(--font-weight-6);
  font-size: var(--font-size-16);
  line-height: 27px;
}

.jobStatus-container {
  display: flex;
  margin: 10px 0 10px 0;
  /* gap: 20px; */
}

/** li **/

.jobStatus-job button {
  text-decoration: none;
  color: var(--color-normalgrey);
  font-family: Poppins;
  font-weight: var(--font-weight-4);
  font-size: var(--font-size-14);
  line-height: 24px;
}

.jobStatus-job button:hover {
  text-decoration: none;
  color: var(--color-lightblue);
  font-family: Poppins;
  font-weight: var(--font-weight-6);
  font-size: var(--font-size-16);
  line-height: 27px;
}
.jobStatus-li {
  /* margin-left: 50px; */
}

.jobStatus-li button {
  text-decoration: none;
  color: var(--color-normalgrey);

  font-family: Poppins;
  font-weight: var(--font-weight-4);
  font-size: var(--font-size-14);
  line-height: 24px;
}

.jobStatus-li button:hover {
  text-decoration: none;
  color: var(--color-lightblue);
  font-family: Poppins;
  font-weight: var(--font-weight-6);
  font-size: var(--font-size-16);
  line-height: 27px;
  transition: font-weight 0.5s;
  transition: font-size 0.5s;
}
.jobStatus-job button:hover {
  text-decoration: none;
  color: var(--color-lightblue);
  font-family: Poppins;
  font-weight: var(--font-weight-6);
  font-size: var(--font-size-16);
  line-height: 27px;
  transition: font-weight 0.5s;
  transition: font-size 0.5s;
}
.jobStatus-container div button {
  width: 150px;
  height: 40px;
  text-align: start;
  display: inline-block;
  border: none;
  background-color: white;
  cursor: pointer;
}
/** JObStatus.jsx End here **/

/** Aside-profile start from here **/

.profile-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/* @media screen and (max-width: 1150px) {
  .profile-container{
   display: none;
  }
  
  } */

.pofile-client-img {
  width: 70px;
  height: 70px;
}
.profile-name-email {
  height: 43px;
  width: 132px;
}
.profile-detail-icon {
  height: 20.54px;
  width: 43.25px;
}

.profile-details {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 250.25px;
  height: 63px;
  margin-right: 23.75px;
  margin-left: 10px;
}

.profile-section-bell {
  width: 50px;
  height: 50px;
  background-color: #eff6ff;
  border-radius: 20px;
  position: relative;
  box-shadow: 0px 8px 32px 0px var(--color-normalblack) 14;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-section-bell a span {
  position: absolute;
  right: 9px;
  top: 5px;
  width: 16px;
  height: 16px;
  font-size: var(--font-size-10);
  background-color: red;
  border-radius: 80px;
  text-align: center;
  color: white;
}

.profile-section-bell a img {
  width: 22.91px;
  height: 22.91px;
  /* margin-top: 20.05px; */
  /* margin-left: 20.05px; */
}

.profile-p-name {
  line-height: 24px;
  font-size: var(--font-size-14);
  font-family: Poppins;
  font-weight: var(--font-weight-5);
  color: var(--color-normalblack);
}
.profile-name-email {
  margin-left: 6px;
  width: 250px;
}
.profile-p-email {
  font-family: Poppins;
  font-weight: var(--font-weight-5);
  font-size: var(--font-size-10);
  line-height: 18px;
  color: #717171;
}

.profile-details-dropdown {
  margin-left: 6px;
}
.profile-details-dropdown a img {
  margin-right: 5px;
}

/**  TodayJobData  start from here  **/

.today-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: 30px 0 30px 0; */
}

.today-job p {
  font-family: Poppins;
  font-weight: var(--font-weight-4);
  font-size: var(--font-size-24);
  line-height: 51px;
}
.checkAll {
  color: var(--color-lightblue);
  font-family: Poppins;
  font-weight: var(--font-weight-5);
  font-size: var(--font-size-20);
  line-height: 36px;
}

.todayJob-checkaAll {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
}
.checkAll:hover {
  color: var(--color-lightblue);
  cursor: pointer;
}
.todayJob-checkaAll button {
  cursor: pointer;

  background-color: white;
  border: none;
}

/**  TodayJobData End here **/

/** Aside-profileData.jsx start from here **/

.profileData-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
}

.calender-container {
  /* background-color: var(--color-normalblack); */
  background-color: var(--color-lightGrey);
  /* color: var(--color-normalwhite); */
  /* color: black; */
  border: 1px solid #acb4b4;
  border-radius: 32px;
  margin-top: 26px;
  /* width: 340px;
  height: 358px; */
}

.calender-container p {
  margin-top: 15px;
  margin-left: 32px;
  font-family: Poppins;
  font-weight: var(--font-weight-5);
  font-size: var(--font-size-20);
  line-height: 36px;
  /* margin-bottom: 24px; */
}

.job-rush-graph {
  width: 320.35px;
  height: 160.18px;

  margin-top: 21px;
}

.profile-map {
  width: 323px;
  height: 233px;

  border-radius: 32px;
  background-color: #f6f6f6;

  margin-top: 20.82px;
  display: flex;
  justify-content: center;
}
/* .calendar-text {
  color: white;
} */
/** Aside-profileData.jsx End here **/

/**  Login.jsx Start from here  **/

.login-container {
  display: flex;

  justify-content: space-between;
  height: 100vh;
  background-color: #eff6ff;
}

.login-img {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */

  flex: 40%;

  position: relative;
  left: 90px;
}

@media screen and (max-width: 950px) {
  .login-img {
   display: none;
  }
}

.login-img span {
  font-size: var(--font-size-36);
  font-family: "Work Sans";
  font-weight: var(--font-weight-7);
  line-height: 2.125rem;
}

.login-main-icon {
  width: 520px;
  position: relative;
  height: 580px;
}
.login-shadow {
  position: absolute;

  height: 580px;
}

/* .login-box {
  border-radius: 40px;

  box-shadow: 0px 0px 40px 0px var(--color-normalblack) 26;
  background-color: var(--color-normalwhite);

  display: flex;
  flex-direction: column;

  height: 40vh;
  align-items: center;
  box-shadow: 0px 0px 40px 0px #00000026;

} */

.login-heading span {
  font-size: var(--font-size-36);
  font-family: "Work Sans";
  font-weight: var(--font-weight-7);
  line-height: 2.125rem;
}

.retailer-logo {
  text-align: center;
}
.retailer-logo span {
  font-size: 48px;
  line-height: 3.813rem;
  font-weight: var(--font-weight-4);
  font-family: "Overpass";
  color: var(--color-lightblue);
}
.retailer-logo p {
  font-size: 4rem;
  line-height: 3.813rem;
  font-weight: var(--font-weight-4);
  font-family: "Overpass";
  color: var(--color-lightblue);
}

.form-container {
  display: flex;
  flex-direction: column;
  gap:6px;
}

.form-container input[type="text"] {
  width: 100%;
  height: 100%;
  border-radius: 21px;
  border: 0.5px solid;
  border-color: #cfcfcf;
  font-size: 1.125rem;
  display: block;
  padding-left: 60px;
}

.form-container input[type="text"]::placeholder {
  font-size: 1.125rem;
  font-weight: var(--font-weight-3);
  line-height: 1.25rem;
  align-items: center;
  font-family: Inter;
}
.password-toggle {
  border-radius: 21px;
  margin-top: 17.47px;
  margin-bottom: 34.43px;
  border: 0.5px solid;
  border-color: #cfcfcf;
  font-size: 1.125rem;
  padding-left: 67px;
}

.text-toggle {
  width: 500px;
  height: 65px;
  border-radius: 21px;
  margin-top: 17.47px;
  margin-bottom: 34.43px;
  border: 0.5px solid;
  border-color: #cfcfcf;
  font-size: 1.125rem;
  padding-left: 67px;
}

.form-container input[type="password"]::placeholder {
  font-size: 1.125rem;
  font-weight: var(--font-weight-3);
  line-height: 1.25rem;
  align-items: center;
  font-family: "Inter";
}

.checkbox {
  font-family: Inter;
  font-weight: var(--font-weight-5);
  font-size: var(--font-size-16);
  line-height: 16px;
  color: var(--color-lightblue);
  display: flex;
  gap: 7px;
  cursor: pointer;
}
.form-container input[type="checkbox"] {
  width: 15px;
  height: 15px;
}
input:focus {
  outline: none;
}
.user-form {
  position: relative;
  width: 500px;
  height: 65px;
}

.login-form-imag {
  width: 30px;
  height: 30px;
  position: absolute;

  top: 16px;
  left: 10px;
  bottom: 5px;

  background-color: white;
}

.eye-btn {
  position: absolute;

  cursor: pointer;
  right: 15px;
  top: 30px;
  border: none;
  background-color: white;
}

.pas-form {
  position: relative;
  width: 500px;
  height: 65px;
  margin-top: 17.47px;
  margin-bottom: 34.43px;
}
.psd-toggle {
  width: 100%;
  height: 100%;
  border-radius: 21px;
  border: 0.5px solid;
  border-color: #cfcfcf;
  font-size: 1.125rem;
  display: block;
  padding-left: 60px;
}

.login-btn {
  width: 500px;
  height: 65px;
  border-radius: 21px;
  margin-top: 10px;
  cursor: pointer;
  border: none;

  font-size: 1.375rem;
  font-weight: var(--font-weight-7);
  line-height: 1.25rem;
  text-align: center;
  background-color: var(--color-lightblue);
  color: var(--color-normalwhite);
  font-family: Inter;
}
.login-btn:hover {
  box-shadow: 0px 4px 10px 0px var(--color-normalblack) 4d;
  transition: box-shadow 0.5s;
}
.form-container a {
  margin-top: 6px;
  text-align: end;
  font-size: var(--font-size-16);
  line-height: 1.361rem;
  font-weight: var(--font-weight-5);
  font-family: Inter;
  color: var(--color-lightblue);
  text-decoration: none;
}

.trouble-login span {
  font-weight: var(--font-weight-4);
  font-size: var(--font-size-16);
  line-height: 1rem;
  font-family: Inter;
  color: #111111;
}
.trouble-login a {
  font-weight: var(--font-weight-4);
  font-size: var(--font-size-16);
  line-height: 1rem;
  font-family: Inter;
  color: var(--color-lightblue);
  text-decoration: underline;
}

.Loging-signForg-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

.Loging-Popup-container {
  /* border: 1px solid; */
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.Loging-Popup-box {
  /* border: 1px solid; */
  height: 550px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* width: 250px; */
}
.LogingPopup-disclaimer {
  font-size: var(--font-size-10);
}

.Loging-Popup-main-heading {
  font-family: Poppins;
  font-size: var(--font-size-20);
  font-weight: var(--font-weight-5);
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
  /* border: 1px solid; */
  border-radius: 10px;
  color: var(--color-lightblue);
}

.Loging-Popup-chechbox-container {
  font-size: var(--font-size-10);
  /* border: 1px solid; */
}
.Loging-Popup-chechbox-container label {
  cursor: pointer;
}
.Loging-Popup-button-container {
  /* border: 1px solid; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.LogingPopup-disclaimer-container {
  /* border: 1px solid; */
}

.Loging-Popup-button-container a {
  text-decoration: none;
  /* border: 1px solid var(--color-dimgrey); */
  background-color: var(--color-lightblue);
  color: white;
  padding: var(---btn-regu-gap);
  border-radius: var(--btn-radius);
}
.Loging-Popup-button-container a:hover {
  box-shadow: 0px 4px 10px 0px var(--color-normalblack) 4d;
  transition: box-shadow 0.5s;
}

.Login_Fgpwd_container {
  /* border: 1px solid; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/** Login.jsx End here  **/

/*  ScanPanel popup Start from here  */
.scanPanel_Popup-Container {
  /* border: 1px solid; */

  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.scanPanel_Popup-box {
  padding: 10px;
  border-radius: 20px;
  background-color: var(--color-lightblue);
  color: white;
  cursor: pointer;
}
.scanPanels-details-container {
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
}
.scanPanels-details-sec-one {
  border: 1px solid;
  flex-grow: 3;
}
.scanPanels-details-sec-two {
  border: 1px solid;
  flex-grow: 1;
}

.scanpanels-img-box {
  border: 1px solid;
}
/*  ScanPanel popup Start from end  */

/* Signup start Here */

.Signup-main-container {
  /* border: 1px solid ; */
  border-radius: 40px;
  height: 100vh;
  padding: 0px 70px;
  display: flex;
  flex-direction: column;
  background-color: var(--color-lightgrey);
}

.Signup-heading-box {
  /* color: var(--color-lightblue); */
  /* text-align: center;
  font-family: Poppins;
  font-size: 42px;
  font-weight: var(--font-weight-5);
  line-height: 63px;
  letter-spacing: 0em; */
  /* border-radius: 10px; */
  /* text-align: left; */
}

.Signup-basicDetails-main-container {
  /* border: 1px solid; */
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
}
.Signup-basicDetails-main-container p {
  font-family: Poppins;
  font-size: var(--font-size-20);
  font-weight: var(--font-weight-6);
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
}
.Signup-basicDetails-boxes {
  /* border: 1px solid; */
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.Signup-basicDetails-boxes input {
  flex: 6;
  padding: 10px 15px;
  border-radius: 20px;
  border: 1px solid var(--color-dimgrey);
}

.Signup-basicDetails-boxes input[type="text"]:focus {
  border: 1px solid var(--color-lightblue);
}
.Signup-basicDetails-boxes input[type="number"]:focus {
  border: 1px solid var(--color-lightblue);
}
.Signup-basicDetails-boxes input[type="email"]:focus {
  border: 1px solid var(--color-lightblue);
}
.Signup-basicDetails-boxes input[type="password"]:focus {
  border: 1px solid var(--color-lightblue);
}

.Signup-autoAdress-input-box:focus {
  border: 1px solid var(--color-lightblue);
}

.Signup-address-boxes-container input[type="text"]:focus {
  border: 1px solid var(--color-lightblue);
}
.Signup-address-boxes-container input[type="number"]:focus {
  border: 1px solid var(--color-lightblue);
}

.Signup-CompanDetail-boxes-label {
  flex: 3;
}
.Signup-basicDetails-boxes-label {
  flex: 2;
}
.Signup-companyDetails-main-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* border: 1px solid; */
  flex: 1;
}

.Signup-companyDetails-main-container p {
  font-family: Poppins;
  font-size: var(--font-size-20);
  font-weight: var(--font-weight-6);
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
}
.Signup-form-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* padding: 20px 0px; */
}

.Signup-Button-main-container button {
  background-color: var(--color-lightblue);
  color: white;
  padding: var(---btn-regu-gap);
  border: none;
  font-family: Poppins;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-6);
  /* line-height: 35px; */
  letter-spacing: 0em;
  text-align: left;
  border-radius: var(--btn-radius);
}
.Signup-conatiner-twoBox {
  /* border: 1px solid; */
  display: flex;
  gap: 100px;
}

.Signup-Button-main-container button:hover {
  box-shadow: 0px 4px 10px 0px var(--color-normalblack) 4d;
  transition: box-shadow 0.5s;
  cursor: pointer;
}

.Signup-Adress-container {
  /* border: 1px solid; */
  display: flex;
  /* align-items: center; */
  /* justify-content: space-between; */
  /* gap: 10px; */
  margin: 10px 0px;
}
.Signup-Adress-boxes input {
  /* width: 100px; */
  padding: 10px;
  border-radius: 10px;
  border: 1px solid var(--color-dimgrey);
  text-align: center;
}

.Signup-Adress-boxes {
  /* border: 1px solid; */
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Signup-Adress-boxes select {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid var(--color-dimgrey);
}

.Signup-address-boxes-container {
  /* border: 1px solid; */
  display: flex;
  gap: 17px;
  padding: 15px;
  flex-wrap: wrap;
  flex: 8;
}

.Signup-address-span {
  flex: 1;
  /* border: 1px solid; */
}

.Signup-autoAdress-input-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* position: relative; */
  /* width: 485px; */
  padding: 5px 10px;
  border: 1px solid var(--color-dimgrey);
  border-radius: 30px;
  background-color: white;
}
.Signup-autoAdress-container {
  /* border: 1px solid; */
  flex: 8;
  display: flex;
  gap: 10px;
  align-items: center;
}

.Signup-autoAdress-input {
  border: none;
  /* flex-grow: 2; */
  min-width: 418px;
  padding: 6px 15px;
  /* margin-left: 8px; */
}

.Signup-autoAdress-border {
  /* border: 1px solid; */
}
.Signup-autoAdress-SearchIcon {
  /* position: absolute; */
  cursor: pointer;
}

.Signup-Address-Number-boxes {
  width: 60px;
}
.Signup-Address-String-boxes {
  width: 180px;
  /* background-color: var(--color-lightblue); */
}
.Signup-menualAdress-container {
  /* margin-top: 20px; */
  /* border: 1px solid; */
}

.Signup-switchingAdress-btn {
  background-color: var(--color-lightblue);
  color: white;
  padding: var(---btn-regu-gap);
  border: none;
  font-family: Poppins;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-6);
  /* line-height: 35px; */
  letter-spacing: 0em;
  text-align: left;
  border-radius: var(--btn-radius);
  cursor: pointer;
}
.Signup-eyebtn {
  border: none;
  background-color: white;
  position: absolute;
  right: 0;
  margin-right: 10px;
}
.signu_eyeBTN-Div {
  position: relative;
}
/* Signup end Here */

/* Forget Password Start from here */

.ForgetPassword-systemDetails-container {
  height: 60vh;
  width: 750px;
  border-radius: 40px;
  background-color: var(--color-lightgrey);
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
  /* border: 1px solid; */
  justify-content: space-between;
  box-shadow: 0px 4px 20px 9px #00000017;
}
.ForgetPassword-main-container {
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.ForgetPassword_main-conatainer-sec1 {
  height: 50vh;
  width: 750px;
  border-radius: 40px;
  background-color: var(--color-lightgrey);
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
  justify-content: space-between;
  box-shadow: 0px 4px 20px 9px #00000017;
}

.ForgetPassword-button-container {
  display: flex;
  justify-content: center;
  align-content: flex-end;
  gap: 20px;
}

.ForgetPassword-Logo-container {
  font-family: Poppins;
  font-size: 42px;
  font-weight: var(--font-weight-5);
  line-height: 63px;
  letter-spacing: 0em;
  /* text-align: left; */
}
.ForgetPassword-heading-container {
  font-size: var(--font-size-34);
  font-weight: var(--font-weight-5);
  line-height: 63px;
  letter-spacing: 0em;
  /* text-align: left; */
}

.ForgetPassword-Email-box {
  /* border: 1px solid var(--color-dimgrey); */
  padding: 10px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
}
.ForgetPassword-Email-box input {
  flex: 2;
  border: 1px solid var(--color-dimgrey);
  padding: 10px;
  border-radius: 10px;
}

.forgetPassword_pswd_input {
  margin-left: 28px;
}
.ForgetPassword-Email-box label {
}
/* Forget Password End from here */

/* create Installer start here */

.CreateInstaller-main-container {
  width: 650px;
  height: 400px;
  background: var(--color-lightgrey);
  border-radius: 40px;
  box-shadow: 0px 4px 20px 9px var(--color-normalblack) 17;
  padding: 50px 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 0px 40px 0px #00000026;
}

.createInstaller-main-heading {
  font-family: Poppins;
  font-size: 42px;
  font-weight: var(--font-weight-5);
  line-height: 63px;
  letter-spacing: 0em;
  /* border: 1px solid; */
}
.createInstaller-pre-heading {
  font-family: Poppins;
  font-size: var(--font-size-20);
  font-weight: var(--font-weight-5);
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
}
.createInstaller-sec2-container {
  /* border: 1px solid; */
  display: flex;
  gap: 100px;
}

.createInstaller-sec1-container {
  /* border: 1px solid; */
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.createInstaller-roles-container {
  /* border: 1px solid; */
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.createInstaller-services-container {
  /* border: 1px solid; */
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.createInstaller-fullname-box {
  /* border: 1px solid; */
  display: flex;
  gap: 10px;
}

.createInstaller-details-boxes input {
  border: none;
  font-family: Poppins;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-4);
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}
.createInstaller-details-boxes label {
  font-family: Poppins;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-4);
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #818181;
}
.createInstaller-details-boxes {
  border: 1px solid var(--color-dimgrey);
  border-radius: 20px;
  padding: var(---btn-regu-gap);
  display: flex;

  background-color: white;
}
.createInstaller-details-boxes:hover{
  border: 1px solid var(--color-lightblue);
}
.createInstaller-details-boxes label:hover{
  color: var(--color-lightblue);
}

.createInstaller-details-boxes:focus-within{
  border: 1px solid var(--color-lightblue);
  color: var(--color-lightblue);
}




.createInstaller-sec3-container {
  padding: var(---btn-regu-gap);
  border-radius: var(--btn-radius);
  border: none;
  background-color: var(--color-lightblue);
  color: var(--color-normalwhite);
  font-family: Poppins;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-6);
  /* line-height: 35px; */
  letter-spacing: 0em;
  cursor: pointer;
}

.InviteInstaller_container{
  display: flex;
  border: 1px solid;
  width: 170px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: white;
  border-radius: var(--btn-radius);
  padding: var(---btn-regu-gap);
  background-color: var(--color-lightblue);
  cursor: pointer;
}
.InviteInstaller_container span{
  font-family: Poppins;
font-size: var(--font-size-16);
font-weight: 400;

letter-spacing: 0em;


}
.InviteInstaller_AllCheckbox{
  /* border: 1px solid; */
  display: flex;
  align-items: center;
  gap: 10px;
}
.InviteInstaller_AllCheckbox_container{
  display: flex;
  /* border: 1px solid; */
  gap: 5px;

}
.InviteInstaller_AllCheckbox_container label{
  cursor: pointer;
}
.InviteInstaller_Btn-container{
  /* border: 1px solid; */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.createInstaller-Roles-main-box {
  /* border: 1px solid; */
  display: flex;
  gap: 10px;
}

.createInstaller-chechboxes input[type="checkbox"]:checked + label img {
  border: 2px solid var(--color-lightblue);
  border-radius: 55px;
}

.createInstaller-chechboxes input[type="checkbox"]:checked ~ span {
  color: var(--color-lightblue);
}

.createInstaller-states-boxes input[type="checkbox"]:checked + label {
  background-color: var(--color-lightblue);
  border: 1px solid var(--color-lightblue);
  color: white;
}

.createInstaller-chechboxes label img {
  border: 2px solid white;
  border-radius: 55px;
  cursor: pointer;
}
.createInstaller-State-main-container {
  display: flex;
  /* border: 1px solid; */
  gap: 10px;
}

.createInstaller-states-labels {
  border: 1px solid #696969;
  padding: 10px 15px;
  border-radius: 10px;
  cursor: pointer;
}
.createInstaller-chechboxes {
  /* border: 1px solid; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.createInstaller-chechboxes span {
  font-family: Poppins;
  font-size: var(--font-size-12);
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
}


.CreateInstaller-PopupClose{
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* create Installer End here */

/*  NotificationBell start from here */
.NotificationBell-container {
  width: 560px;
  height: 520px;
  background-color: var(--color-lightgrey);
  border-radius: 40px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 4px 20px 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
}
.NotificationBell_popupCloseBtn{
  /* border: 1px solid; */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 60px;

}

/*  NotificationBell end from here */

/* InstallerResetPswd-container */
.installer-container-pswd {
  display: flex;
  justify-content: center;
  align-items: center;
}
.InstallerResetPswd-container {
  /* border: 1px solid var(--color-normalgrey); */
  border-radius: 40px;
  box-shadow: 0 4px 20px 9px #00000017;
  background-color: #f0f4f4;
  padding: 30px 30px;
  height: 90vh;
  margin: 30px;
  width: 800px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.InstallerResetPswd-main-heading {
  font-size: 35px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 63px;
}

.InstallerResetPswd-input-container {
  align-items: center;
  border: 1px solid #d3d3d3;
  border-radius: 20px;
  display: flex;
  gap: 10px;
  padding: 20px;
  display: flex;
  gap: 20px;
  align-items: center;
}

.InstallerResetPswd-input-container input {
  flex: 2 1;
  border: 1px solid var(--color-dimgrey);
  padding: 10px;
  border-radius: 10px;
}
.InstallerResetPswd-btn-container {
  /* border: 1px solid; */
  display: flex;
  justify-content: space-evenly;
  margin-top: 30px
}
.InstallerResetPswd-btn-container button {
  border: none;
  color: white;
  background-color: var(--color-lightblue);
  cursor: pointer;
  font-family: Poppins;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-6);
  line-height: 35px;
  letter-spacing: 0em;
  /* text-align: left; */
  padding: var(---btn-regu-gap);
  border-radius: var(--btn-radius);
}
.InstallerResetPswd-form-container{
  display: flex;
  flex-direction: column;
  gap: 20px;
}
/* InstallerResetPswd-container */



/* Add Installer Menualy  */
.AddInstallerM-container{
  background-color: #f0f4f4;
  border-radius: 40px;
  box-shadow: 0 4px 20px 9px #00000017;
  display: flex;
  flex-direction: column;

  height: 90vh;
  overflow: scroll;
  padding: 20px 40px;
  width: 1000px;
}

.AddInstallerM_Main_heading{
  color: #000;
    font-family: Poppins;
    font-size: var(--font-size-38);
    font-weight: 500;
    letter-spacing: 0;
    line-height: 63px;
    text-align: left;
}
.AddInstallerM_Lines_containers{
  display: flex;
  gap: 10px;
  /* border: 1px solid; */
}

.AddInstallerM_input_boxes{
  align-items: center;
    background-color: #fff;
    border: 1px solid #d3d3d3;
    border-radius: 20px;
    color: #818181;
    display: flex;
    gap: 10px;
    padding: 5px 15px;
}

.AddInstallerM_input_boxes select{
  border: none;
}
.AddInstallerM_input_boxes input{
  border: none;
    color: #000;
    font-family: Poppins;
    font-size: var(--font-size-16);
    width: 160px;
}

.AddInstallerM_Role_container{
  border: 1px solid #d3d3d3;
  border-radius: 20px;
  display: flex;
  padding: var(---btn-regu-gap);

  align-items: center;
  background-color: #fff;
  border: 1px solid #d3d3d3;
  border-radius: 20px;
  color: #818181;
  display: flex;
  gap: 107px;
  padding: 5px 15px;
}
.AddInstallerM_Role_boxes{
  display: flex;
  gap: 10px;
}
.AddInstallerM_CEC_containers{
  border: 1px solid #d3d3d3;
  border-radius: 20px;
  display: flex;
  padding: var(---btn-regu-gap);

  align-items: center;
  background-color: #fff;
  border: 1px solid #d3d3d3;
  border-radius: 20px;
  color: #818181;
  display: flex;
  gap: 10px;
  padding: 5px 15px;
}
.AddInstallerM_CEC_containers input{
  border: 1px solid var(--color-dimgrey);
  border-radius: var(--btn-radius);
  padding: var(---btn-regu-gap);
}
.AddInstallerM_validate_containers{
  border: 1px solid #d3d3d3;
  border-radius: 20px;
  display: flex;
  padding: var(---btn-regu-gap);

  align-items: center;
  background-color: #fff;
  border: 1px solid #d3d3d3;
  border-radius: 20px;
  color: #818181;
  display: flex;
  gap: 10px;
  padding: 5px 15px;
}

.AddInstallerM_CECFILE_containers{
  border: 1px solid #d3d3d3;
  border-radius: 20px;
  display: flex;
  padding: var(---btn-regu-gap);
  align-items: center;
  background-color: #fff;


  color: #818181;


gap: 10px;
}

.AddInstallerM_Permission_boxes{
  border: 1px solid #d3d3d3;
  border-radius: 20px;
  display: flex;
  padding: var(---btn-regu-gap);

  align-items: center;
  background-color: #fff;
  border: 1px solid #d3d3d3;
  border-radius: 20px;
  color: #818181;
  display: flex;
gap: 40px;
  padding: 5px 15px;
}

.AddInstallerM_Permission1_boxes{
  border: 1px solid #d3d3d3;
  border-radius: 20px;
  display: flex;
  padding: var(---btn-regu-gap);

  align-items: center;
  background-color: #fff;
  border: 1px solid #d3d3d3;
  border-radius: 20px;
  color: #818181;
  display: flex;
gap: 40px;
  padding: 5px 15px;
}

.AddInstallerM_Permission2_boxes{
  border-radius: 20px;
  display: flex;
  padding: var(---btn-regu-gap);

  align-items: center;
  background-color: #fff;
  border: 1px solid #d3d3d3;
  border-radius: 20px;
  color: #818181;
  display: flex;
gap: 40px;
  padding: 5px 15px;
}

.AddInstallerM_basicDetails{
  /* border: 1px solid; */
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.AddInstallerM_rolesDetails{
  /* border: 1px solid; */
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.AddInstallerM_Permissions{
  /* border: 1px solid; */
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.AddInstallerM_btn-container{
  /* border: 1px solid; */
  display: flex;
  justify-content: center;
  gap: 10px;
}

.AddInstallerM_btn-container button{
  background-color: var(--color-lightblue);
  color: white;
  padding: var(---btn-regu-gap);
  border: none;
  font-family: Poppins;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-6);
  /* line-height: 35px; */
  letter-spacing: 0em;
  text-align: left;
  border-radius: var(--btn-radius);
  cursor: pointer;
}

.AddInstallerM-form-container{
  /* border: 1px solid; */
  display: flex;
  flex-direction: column;
  gap: 50px;
}


.AddInstaller-PopClose{
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.AddInstallerM_permission_input_boxes{
  display: flex;
  gap: 10px;

}

.AddInstallerM_permission_input_boxes input[type="checkbox"]:checked + label {
  color: var(--color-lightblue);
}

.AddInstallerM_Role_boxes input[type="checkbox"]:checked + label {
  color: var(--color-lightblue);
}



.AddInstallerM_permission_input_boxes label{
  cursor: pointer;
}
.AddInstallerM_searchAdress-input{
  border: 1px solid #d3d3d3;
    /* display: flex; */
    /* align-items: center; */
    border-radius: 20px;
    padding: 6px 25px;
    /* justify-content: space-between; */
    display: flex;
    align-items: center;
    flex: 1 1;
    background-color: white;
}
.AddInstallerM_searchAdress-input input{
  border: none;
    flex: 1 1;
    font-family: Poppins;
    font-size: var(--font-size-16);
    width: 655px;

}


.AddinstallerM-MenualAdressInput_container{

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.AddInstallerM_input_boxes:focus-within {
  border: 1px solid var(--color-lightblue);
  color: var(--color-lightblue);
}
.AddInstallerM_input_boxes:hover{
  border: 1px solid var(--color-lightblue);
  color: var(--color-lightblue);
}
.AddInstallerM_searchAdress-input:focus-within{
  border: 1px solid var(--color-lightblue);
  color: var(--color-lightblue);
}

.AddInstallerM_searchAdress-input:hover{
  border: 1px solid var(--color-lightblue);
  color: var(--color-lightblue);
}
.AddInstallerM_Role_container:hover {
  border: 1px solid var(--color-lightblue);

}
.AddInstallerM_Role_container p:hover{
  color: var(--color-lightblue);
}

.AddInstallerM_CEC_containers:hover{
  border: 1px solid var(--color-lightblue);

}
.AddInstallerM_CEC_containers p:hover{
  color: var(--color-lightblue);
}
.AddInstallerM_CEC_containers:focus-within{
  border: 1px solid var(--color-lightblue);
  color: var(--color-lightblue);
}
.AddInstallerM_CECFILE_containers input{
  width: 200px;


}

.AddInstallerM_cec1-boxes input:focus-within{
  border: 1px solid var(--color-lightblue);

}
.AddInstallerM_cec1-boxes input:hover{
  border: 1px solid var(--color-lightblue);

}
.AddInstallerM_cec2-boxes input:focus-within{
  border: 1px solid var(--color-lightblue);

}
.AddInstallerM_cec2-boxes input:hover{
  border: 1px solid var(--color-lightblue);

}
.AddInstallerM_cec3-boxes input:focus-within{
  border: 1px solid var(--color-lightblue);

}
.AddInstallerM_cec3-boxes input:hover{
  border: 1px solid var(--color-lightblue);

}

.AddInstallerM_validate_containers:hover{
  border: 1px solid var(--color-lightblue);
 
}
.AddInstallerM_validate_containers p:hover{
  color: var(--color-lightblue);
}
.AddInstallerM_CECFILE_containers:hover{
  border: 1px solid var(--color-lightblue);

}
.AddInstallerM_CECFILE_containers p:hover{
  color: var(--color-lightblue);
}

.AddInstallerM_validate1_date_boxes{
margin-left: 27px;
}
.AddInstallerM_validate2_date_boxes{
  margin-left: 70px;
}
.AddInstallerM_validate3_date_boxes{
  margin-left: 67px;
}
.AddInstallerM_permission_input_boxes label:hover{
  color: var(--color-lightblue);
}
.AddInstallerM_Permission_boxes:hover{
  border: 1px solid var(--color-lightblue);
}
.AddInstallerM_cec2-boxes{
  margin-left: 25px;

}

.AddInstallerM_cec3-boxes{
  margin-left: 30px;
 
}
.AddInstallerM_Role_boxes label{
  cursor: pointer;
}
#Addinstaller-PersonPhoto{
  border: none;
  color: #000;
  font-family: Poppins;
  font-size: var(--font-size-12);
  width: 200px;
}
#AddinstallerLogo{
  border: none;
  color: #000;
  font-family: Poppins;
  font-size: var(--font-size-12);
  width: 170px;
}

.AddInstaller_Permissions_heading{
  font-size: var(--font-size-22);
  font-family: Poppins;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  
}
/* Add Installer Menualy  */


/* testing  */

.test_container{
  border: 1px solid;
  display: flex;
  gap: 10px;

}
.test_plusBtn{
  border: 1px solid;
  min-width: 140px;
  min-height: 140px;
  border-radius: 20px;
}
.test_statusBox{
  border: 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  flex: 1;
  gap: 20px;
}

.test_firstbox{
  border: 1px solid;
  padding: 20px;

}
.test_secondbox{
  border: 1px solid;
  padding: 20px;
}
.test_Thirdbox{
  border: 1px solid;
  padding: 20px;
}
/* testing  */


/* EditJob Start from here */
.editjob-outer-container{
padding: 30px;
}
.editjob-main-container{
  background-color: #F0F4F4;
  box-shadow: 0px 4px 20px 9px #00000017;
border-radius: 40px;
padding: 30px;
}

.panelscan_main-table thead tr th{
padding: 20px 30px;
border-radius: 20px;
color: #ffffff;

}

.panelscan_main-table thead{
  background-color: #5fa4fb;


}
.panelscan_main-table tbody tr td{
  padding: 20px 30px;

}

.submitBtnEditJob {
  background-color: #5FA4FB;
  border: none;
  color: white;
  border-radius: 40px;
  padding: 6px 14px;
  font-size: 16px;
  cursor: pointer;
}
/* EditJob End Here */

/* Delete Installer Start */
.installerSuspend_main-container {
  display:flex;
  flex-direction: column;
}
.Installersuspend-heading {
  font-size: 22px;
}

.installer_suspect_buttn {
  padding: 6px 14px;
  background-color: #5FA4FB;
  border: none;
  border-radius: 20px;
  color: white;
  cursor: pointer;
}

/* Delete Installer End */

