.imageUploadField{
  display: flex;
  flex-direction: column;
}

.imageUploadSectionFolder{
padding:2% 0
}

.uploadSection{
margin-top:4%
}

.ShowImages{
padding-left: 1%;
display: flex;
flex-wrap: wrap;
/* gap:10px */
}
.LabelAndImage{
/* position: relative; */
display: flex;
flex-direction: column;
gap: 10px;
/* margin-top:5%; */
}
.labelTextForImage{
/* position: absolute; */
top: -0px;
/* right: -91px; */
color: rgba(44, 58, 100, 1);
font-size: 12px;
font-weight: 400;
width: fit-content;

}
.label-Imageupload{
width: 220px;
left: 3px;
position: relative;
top:-42px;
}
.ImagesUploadDiv{
display: flex;
align-items:center;
margin-bottom: 10px;
padding: 10px;
border-radius: 5px;
gap: 13px;
flex-wrap: wrap;
}
.ShowImagesDiv{
display: flex;
flex-wrap: wrap;
/* gap:20px */
}
.PhotoMainContainer{

}

.PhotoSectionHeading{
  color: rgba(44, 58, 100, 1);
  font-size: 18px;
  font-weight: 700;
  /* line-height: 19px; */
  padding: 20px 13px;
}
.imageUploadInputField{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 345px;
  height: 52px;
  background-color: #E0EDFF;
  border-radius: 5px;
  margin-top: 2%;
}

.imageBlock{
  background-color: aliceblue;
  padding:15px;
  position: relative;
  margin-top: 2%;
}
.imageDeleteButton{
  position: absolute;
  top:4px;
  right:5px;
  background: none;
  border-radius: 50px;
  width: 23px;
  height: 23px;
  /* border: 1px solid red; */
}
.uploadButton{
  background-color: #E0EDFF;
  border: none;
  cursor: pointer;
  position:absolute

}